<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body class="cancel-top">
          <b-card-body>
            <div class="cancel-text">
              <feather-icon
                class="alert-icon"
                icon="AlertTriangleIcon"
              />
              <span class="cancel-title">
                  Dados de cancelamento de conta
              </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <!-- chart -->
          <b-card-body class="offset-1 col-10">
            <div class="cancel-select">
              <b-form-group
              >
                <v-select
                  class="cancel-date-select"
                  placeholder="day / week / month"
                  :searchable="false"
                />
              </b-form-group>
            </div>
            <chartjs-component-bar-chart
              :height="400"
              :data="chartjsData.latestBarChart.data"
              :options="chartjsData.latestBarChart.options"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody,
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  BTable, BFormCheckbox, BAvatar, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup, 
    BFormInput, 
    BInputGroup, 
    BInputGroupPrepend,
    BTable, 
    BFormCheckbox, 
    BAvatar, 
    BBadge,
    vSelect,
    flatPickr,
    ChartjsComponentBarChart,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      chartjsData,
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>