<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <user-cancel-grafics />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <user-cancel-comments />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import UserCancelGrafics from './UserCancelGrafics.vue'
import UserCancelComments from './UserCancelComments.vue'

export default {
  components: {
    BRow,
    BCol,
    UserCancelGrafics,
    UserCancelComments
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-cancel.scss';
</style>
